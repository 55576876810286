/*FONTS*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*MAIN*/

.dangerInModal {
  color: red;
  animation: dangerLoopInModal 1.8s ease-in-out infinite;
}

@keyframes dangerLoopInModal {
  0% {
    color: #444;
  }
  15% {
    color: #444;
  }
  25% {
    color: #ff0000;
  }
  40% {
    color: #444;
  }
  50% {
    color: #ff0000;
  }
  65% {
    color: #444;
  }
  100% {
    color: #444;
  }
}

.danger {
  color: red;
  animation: dangerLoop 1.8s ease-in-out infinite;
}

@keyframes dangerLoop {
  0% {
    color: #fff;
  }
  15% {
    color: #fff;
  }
  25% {
    color: #ff0000;
  }
  40% {
    color: #fff;
  }
  50% {
    color: #ff0000;
  }
  65% {
    color: #fff;
  }
  100% {
    color: #fff;
  }
}

.animation-fadeIn {
  animation: fadeIn 1s forwards;
}

.logo_main {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  width: 200px;
}

body {
  color: #000;
  font-family: "Poppins" !important;
  background: rgb(246, 246, 246);
}

p {
  font-size: 1em;
}

label.title {
  color: #2e2e2e;
  font-size: 24px;
  font-weight: 300;
}

label {
  color: #2e2e2e;
  font-size: 15px;
  font-weight: 500;
  margin-right: 5px;
}

h1 {
  font-weight: 700;
  font-size: 2em;
}

h1,
h2,
h5,
h6 {
  margin: 10px;
}

h2 {
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
}

h3 {
  margin-top: 60px;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0;
}

h4 {
  color: #16191c;
  font-size: 16px;
  font-weight: 500;
  text-transform: initial;
}
button {
  font-family: "Poppins";
  cursor: pointer;
  border: none;
  background: none;
}

span {
  padding: 5px;
  border-radius: 3px;
  text-decoration: none;
}

hr {
  margin-top: 50px;
  border-radius: 10%;
  border-bottom: 2px solid rgba(68, 68, 68, 0.3) !important;
  z-index: 5;
  width: 130px;
  border: none;
}

.signature {
  text-align: center;
  position: relative;
  border: 1px solid rgba(68, 68, 68, 0.274);
  height: 100%;
  border-radius: 7px;
}

.signature canvas {
  height: 250px;
}

.container {
  width: 100%;
  text-align: center;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  animation: fadeIn 500ms ease-in-out;
  padding-bottom: 100px;
}

.print {
  position: relative;
  width: 820px;
  margin: auto;
  background-color: #fff;
}

.print h4 {
  font-size: 18px;
}

.print p {
  font-size: 16px;
}

.print > img,
.logo-print {
  width: 250px;
  margin: auto;
  margin-top: 50px;
  position: relative;
  display: block;
}

.print input {
  transform: scale(1.3);
  margin-right: 10px;
}

.signature-img {
  object-fit: scale-down;
  width: 120px;
  position: absolute;
  height: 120px;
  z-index: 10;
}

.background-page {
  width: 100%;
  height: 92vh;
  background-color: rgb(246, 246, 246);
}

.footer-absence {
  width: 100%;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-top: 100px;
  text-align: center;
  display: block;
}
.footer-absence p {
  margin: 1px;
  color: #888888;
  font-size: 14px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range:hover {
  background-color: #30c4f5;
}

.react-datepicker__day--in-selecting-range {
  background-color: #30c4f595;
}

/*NAVBAR*/

.menu {
  position: absolute;
  left: 0;
  top: 100px;
  margin: auto;
  transition: 0.2s;
}

.nav-item:hover {
  background-color: #30c4f5;
}

.nav-item.active > img {
  filter: invert(1);
}

.nav-item > img {
  width: 60px;
  padding: 15px;
  transition: 0.2s;
  filter: invert(0.7);
}

.nav-item:hover > img {
  transform: scale(1.02);
  transition: 0.2s;
  filter: invert(1);
}

.nav-item:nth-child(1) {
  border-top: 1px solid #00000010;
}

.nav-item {
  line-height: 30px;
  float: left;
  width: 100px;
  left: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  text-transform: initial;
  border-bottom: 1px solid #00000010;
}

.tooltip-nav > span {
  visibility: hidden;
  position: absolute;
}

.tooltip-nav:hover > span {
  visibility: visible;
  position: fixed;
  width: auto;
  left: 110px;
  margin-top: 35px;
  background-color: #fff;
  border-radius: 7px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  box-shadow: 2px 2px 5px 5px #00000025;
  animation: fadeIn 200ms ease-in-out;
}

.tooltip-add > span,
.tooltip-import > span {
  visibility: hidden;
  position: absolute;
}
.tooltip-import:hover > span {
  visibility: visible;
  position: absolute;
  width: 180px;
  right: 100px;
  bottom: 120px;
  background-color: #fff;
  border-radius: 7px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  box-shadow: 2px 2px 5px 5px #00000025;
  animation: fadeIn 200ms ease-in-out;
}

.tooltip-add:hover > span {
  visibility: visible;
  position: absolute;
  width: 180px;
  right: 100px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 7px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  box-shadow: 2px 2px 5px 5px #00000025;
  animation: fadeIn 200ms ease-in-out;
}

.tooltip-filter:hover > span {
  right: -70px;
  width: 70px;
}

.nav-item.active {
  background-color: #30c4f5;
}

.right > .nav-item:nth-child(3) {
  margin-right: 8px;
}

.badge::after {
  color: #fff;
  content: "";
  background-color: rgb(211, 73, 73);
  border-radius: 100px;
  width: 17px;
  height: 17px;
  top: 10px;
  right: 3px;
  z-index: 0;
  position: absolute;
}

.badge-number {
  right: 3px;
  position: absolute;
  color: #fff;
  top: 6px;
  z-index: 1;
  font-size: 13.6px;
  font-weight: bold;
}

.round-account.profil p {
  margin-top: 0px;
  text-align: center;
  font-weight: 500;
}

.round-account.profil {
  width: 110px;
  height: 110px;
  margin: auto;
  margin-top: 20px;
}

.round-account {
  background-color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.082);
  transition: 0.3s;
  border: 1px solid #e0e0e0a9;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.round-account > img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.square-account {
  background-color: #fff;
  border-radius: 7px;
  width: 80%;
  height: 80%;
  margin: auto;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.082);
  transition: 0.3s;
  border: 1px solid #e0e0e0a9;
  animation: fadeIn 500ms ease-in-out;
  position: relative;
}

.square-account > img {
  border-radius: 7px;
  width: 100%;
  height: 100%;
  filter: grayscale(1);
  transition: 0.3s;
  object-fit: cover;
}

.square-account::after {
  content: "";
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 30px;
  z-index: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #00000071;
  transition: 0.3s;
  backdrop-filter: blur(5px);
}

.block-item:hover > .square-account::after {
  transition: 0.3s;
  height: 45px;
}

.block-item:hover > .square-account {
  transition: 0.3s;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.4);
}

.block-item:hover .square-account > img {
  transition: 0.3s;
  filter: grayscale(0);
}

.name-account {
  color: #fff !important;
  bottom: 10px !important;
}

.modal .react-datepicker__portal {
  height: 98% !important;
}

.sidebar .closeModal {
  left: 20px;
  top: 52px;
}

.navbar {
  right: 0;
  top: 0;
  z-index: 59;
  position: fixed;
  background-color: #fff;
  transition: 0.3s;
  width: 100%;
  height: 60px;
  border-top: 1px solid #00000011;
  border-bottom: 1px solid #00000011;
  box-shadow: 0 1px 5px 0.5px rgba(22, 25, 28, 0.05);
}

.sidebar li {
  display: flex;
  height: 35px;
}

.sidebar li p {
  margin: 7px;
  font-size: 12px;
}

.sidebar {
  z-index: 999;
  position: fixed;
  width: 250px;
  height: 100%;
  padding: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background-color: #fff;
  transition: 0.3s;
  border-top: 1px solid #00000011;
  border-right: 1px solid #00000011;
  animation: slide-sidebar 300ms ease-in-out;
  overflow: auto;
}

.sidebar.right {
  right: 0;
  left: auto;
  animation: slide-sidebar-right 300ms ease-in-out;
}

@keyframes slide-sidebar {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-sidebar-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.dropdown-user {
  display: inline-flex;
  background-color: #fff;
  z-index: 15;
  border: 1px solid #16191c28;
  border-radius: 6px;
  height: auto;
  position: absolute !important;
  top: -400px;
  width: 150px;
  box-shadow: 0 0 10px 5px #00000017;
  animation: fadeIn 100ms ease-in-out;
}

.dropdown {
  z-index: 995;
  background-color: #fff;
  z-index: 15;
  border: 1px solid #16191c28;
  border-radius: 6px;
  height: auto;
  animation: fadeIn 500ms ease-in-out;
}

.dropdown ul > li:nth-child(1) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.dropdown-user ul > li:nth-child(1) {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.dropdown-user ul > li,
.dropdown.list ul > li {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}
.dropdown-user ul li > p,
.dropdown ul li > p {
  font-size: 15px;
}

.closeFilter {
  width: 0 !important;
  visibility: hidden !important;
}

.doc {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 60;
  top: 0;
  text-align: center;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-help {
  z-index: 60;
  position: fixed;
  margin-top: 95px;
  right: 253px;
  width: 254px;
  transition: 0.5s;
}

.dropdown-help > p {
  padding: 15px;
  margin: 0;
  cursor: pointer !important;
  background-color: #16191c09;
  width: 225px;
}

.dropdown-help p > a {
  text-decoration: none !important;
  color: #16191c !important;
}

.dropdown-help > p:hover {
  background-color: #16191c1c;
}

.openFilter {
  margin-right: 35px;
  box-shadow: 5px 5px 10px 5px #0d0d0d1a !important;
  width: 75% !important;
}

.products-settings {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 50;
  height: 90px;
  transition: 0.3s;
}

.btn--filter div > img:hover {
  transform: scale(1.06);
  transition: 0.5s;
  box-shadow: 2px 2px 10px 5px #16191c23;
}

.btn--filter div > img {
  transition: 0.3s;
}

.btn--filter {
  position: fixed;
  bottom: 0;
  left: 220px;
  z-index: 50;
  height: 90px;
}

.icon-btn-nav {
  position: fixed;
  padding: 5px;
  transition: 0.3s;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 2px 2px 5px 5px #16191c10;
  width: 40px;
  cursor: pointer;
  right: 32px;
  z-index: 10;
}

a:hover > .icon-btn-nav {
  transform: scale(1.06);
  transition: 0.3s;
  box-shadow: 2px 2px 10px 5px #16191c23;
}

.btn-task > p {
  left: -10px;
  position: relative;
  top: 10px;
  width: 80%;
  line-height: 1.2;
  text-align: left;
  color: #fff;
  transition: 0.8s;
}

.btn-task:hover > p {
  transition: 0.8s;
  color: #16191c;
  transform: translateY(5px) translateX(10px) scale(1.1);
}

.btn-task {
  cursor: pointer;
  z-index: 999;
  padding-right: 30px;
  padding-left: 30px;
  display: block;
  height: 100%;
  position: relative;
  background-color: #30c5f5;
  font-size: 1em;
  transition: 0.5s;
  color: #fff;
  text-transform: initial;
  font-weight: 300;
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  line-height: 2.7;
  font-weight: 900;
  top: -4px;
}

.help {
  background-color: #93c93c;
  width: 254px;
}

.btn-task:hover > img {
  filter: invert(0);
  transition: 0.5s;
}

.btn-task:hover {
  border: 0;
  transition: 0.3s;
  background-color: transparent !important;
  color: #16191c;
}

button.red {
  background-color: #ff004d;
}

button:hover.red {
  background-color: #fd3974 !important;
}

button.green {
  background-color: #93c93c;
}

button:hover.green {
  background-color: #a6d063 !important;
}

button,
.btn {
  z-index: 10;
  height: 55px;
  width: 90%;
  border-radius: 15px;
  background-color: #30c5f5;
  font-size: 1em;
  transition: 0.5s;
  color: #fff;
  font-weight: 700;
  margin: auto;
  margin-top: 30px;
  display: block;
}

button:hover,
.btn:hover {
  border: 0;
  transition: 0.3s;
  background-color: #6acef0 !important;
  transform: scale(1.05);
}

.text-legende {
  position: fixed;
  right: 18%;
  top: -5px;
  z-index: 61;
  cursor: default;
  line-height: 35px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.text-legende:hover {
  border-bottom: 2px solid #000000a1;
  transition: all 0.5s;
}

.legende {
  position: fixed;
  width: 30%;
  top: 100px;
  right: 30px;
  text-transform: initial;
  background-color: #fff;
  border-radius: 7px;
  padding: 15px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.09);
}

.legende > p {
  font-weight: 500;
}

.legende > p:nth-child(2) {
  color: #30c5f5;
}

.legende > p:nth-child(3) {
  color: #ff8e25;
}

.legende > p:nth-child(4) {
  color: #ef5a9b;
}

.legende > p:nth-child(5) {
  color: #93c93c;
}

.atraiter {
  border: 1px solid rgba(68, 68, 68, 0.1);
  background-color: #30c5f5 !important;
}

.encours {
  border: 1px solid rgba(68, 68, 68, 0.1);
  background-color: #ff8e25 !important;
}

.ok {
  border: 1px solid rgba(68, 68, 68, 0.1);
  background-color: #93c93c !important;
}

.priorite {
  border: 1px solid rgba(68, 68, 68, 0.1);
  animation: prioriteAnim 1.8s ease-in-out infinite;
}

@keyframes prioriteAnim {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  15% {
    box-shadow: 0 0 0 0 transparent;
  }
  25% {
    box-shadow: 0 0 2px 1px #ff000065;
  }
  40% {
    box-shadow: 0 0 0 0 transparent;
  }
  50% {
    box-shadow: 0 0 2px 1px #ff000065;
  }
  65% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.icon-download > img {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 250px;
  top: 11px;
  padding: 5px;
  transition: 0.3s;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 2px 2px 5px 5px #16191c10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.icon-download > img:hover {
  transform: scale(1.05);
}

.attente_client {
  border: 1px solid rgba(68, 68, 68, 0.1);
  background-color: #ef5a9b !important;
}

.tooltip_disabled {
  cursor: default !important;
  transform: none !important;
  filter: invert(0.2) !important;
}

.settings {
  position: relative;
  width: 11% !important;
  top: 7px;
  display: inline-flex;
}

.icon-setting {
  position: relative;
  top: 0px;
  width: 35px;
  cursor: pointer;
  filter: invert(1);
  transition: 0.2s;
  margin-right: 20px;
}

.icon-setting:hover {
  transform: scale(1.05);
  transition: 0.2s;
  filter: invert(0.9);
}

.card-item * {
  margin: 0;
}

.card-item {
  margin: 15px;
  width: auto;
  min-height: auto;
  max-height: 200px;
  height: auto;
  padding: 20px;
  position: relative;
  transition: all 0.4s;
  text-align: left;
  border-radius: 25px;
  border: 0;
  box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
  cursor: pointer;
}

.card-item:hover {
  transform: scale(1.015);
  transition: all 0.1s;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
}

.card-item-info div div > p {
  font-size: 18px;
  width: auto !important;
}

.card-item-info div > div {
  width: 20%;
}

.card-item-info {
  font-size: 22px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  transform: none !important;
  height: 45px;
}

/*INPUT*/

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
  background-image: none;
  z-index: 1;
  border: 1px solid #ececec;
  color: #444 !important;
}

option {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select.date {
  position: relative;
  display: flex;
  width: 16.6%;
  height: 44px;
  line-height: 3;
  background: rgba(63, 63, 62, 0.59) !important;
  overflow: hidden;
  border-radius: 0.25em;
}

.select.conge {
  margin-top: 35px;
  margin-bottom: 15px;
}

.select {
  text-align: left;
  border-radius: 0.25em;
  min-height: 44px !important;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

select {
  background-color: #f7f7f7 !important;
  padding: 10px !important;
  border: 1px solid #ececec !important;
  box-shadow: none !important;
  cursor: pointer !important;
  width: 100% !important;
  border-radius: 5px;
  margin-top: 15px;
}

.react-select__control {
  background-color: #f7f7f7 !important;
  min-height: 44px !important;
  border: 1px solid #ececec !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.select:hover .react-select__control {
  border: 1px solid #16191c2c !important;
  transition: 0.2s;
}

.react-select__option--is-focused {
  background-color: #cbf2ff !important;
  cursor: pointer !important;
}

.react-select__option--is-selected {
  background-color: #30c4f5 !important;
  color: #fff !important;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}

select {
  display: block !important;
  width: auto;
  position: relative;
  text-transform: initial;
  font-family: "Poppins";
}
select:focus,
.btn-style-input:hover {
  border: 1px solid #dadada !important;
  background: #f7f7f7 !important;
  transform: none;
}

.btn-style-input {
  width: 100% !important;
  margin: auto;
}

.input,
.btn-style-input {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #ececec;
  box-shadow: 0 0 7px 0px rgba(49, 49, 49, 0.03);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  margin-bottom: 16px;
  width: 250px;
  box-sizing: border-box;
  transition: 0.15s;
  padding-left: 10px;
  top: 0;
  right: 0;
  font-family: "Poppins";
}

.input:hover,
select:hover {
  border: 1px solid #16191c2c;
  transition: 0.2s;
}

.input:focus {
  border: 1px solid #16191c2c;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.013);
  transition: 0.2s;
}

/*BOUTON*/

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnItem-filter {
  width: 100%;
  height: 33%;
  background-color: rgba(80, 80, 80, 0.068);
  font-size: 15px;
  transition: 0.2s;
  color: #313131;
  border: 1px solid #fff;
}

.btnItem-filter:hover {
  background-color: rgba(80, 80, 80, 0.108);
}

.btn-filter {
  height: 43px;
  width: 100px;
  position: relative;
  font-size: 14.4px;
  margin-right: 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #313131;
  text-transform: initial;
  transition: 0.3s;
  letter-spacing: 0.6px;
}

.btn-filter:hover,
.btn-filter:focus {
  background-color: #b9b9b928;
  transition: 0.3s;
}

.btn {
  text-align: center;
  line-height: 3.3;
}
/*LODAER*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*MODAL*/

.background-modal {
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: fadeIn 0.3s normal forwards;
  cursor: default;
}

.container-modal {
  padding: 30px;
}

b {
  font-weight: 600;
}

.container-modal p {
  font-size: 14px;
}

.modal {
  z-index: 999;
  background-color: #fff;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 2px 2px 10px 2px #00000010;
  animation: openModal 0.3s normal forwards;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal input,
.modal textarea,
.modal .select {
  width: 100%;
}

.modal input[type="radio"] {
  width: auto;
}

.yes {
  background-color: rgba(255, 0, 0, 0.781);
  height: 50px;
  width: 80px;
}

.yes:hover {
  color: rgba(255, 0, 0, 0.781);
}

.no {
  background-color: #93c93c;
}

.no:hover {
  color: #93c93c;
}

/*CONTAINER*/

.card-block {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
}

.card-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  width: auto;
  z-index: 1;
  margin-top: 20px;
  padding-right: 50px;
}

.extract-container {
  margin-top: 150px !important;
  position: relative;
  width: 350px;
  margin: auto;
  left: 50px;
  padding-bottom: 100px;
}

.extract-container > button {
  border-radius: 7px;
  border: 1px solid transparent;
}

.extract-container > button:hover {
  border: 1px solid #00000019;
}

.extract-container > select {
  width: 100% !important;
}

.date-container {
  margin-top: 90px !important;
  position: relative;
  width: 92%;
  margin: auto;
  left: 50px;
  padding-bottom: 100px;
}

@keyframes grayFadeIn {
  0% {
    filter: blur(2px);
  }
  100% {
    filter: blur(0);
  }
}

.closeModal {
  color: #000;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 49px;
  font-size: 32px;
  text-decoration: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: 0;
  line-height: 0.8;
  padding: 5px;
  transition: 0.3s;
  z-index: 70;
}

.closeModal:hover {
  background-color: transparent !important;
  transform: scale(1.05);
  transition: 0.3s;
}

.closeModal > p {
  transition: 0.3s;
  line-height: 0.5;
  right: -2px;
  margin-top: 3px;
  position: relative;
  font-weight: 300;
  font-size: 40px;
}

.background-menuMobile {
  background-color: #0000003d;
  width: 100%;
  height: 100%;
  position: fixed;
  animation: fadeIn 500ms ease-in-out;
}

.rolling-in {
  background-color: #fff;
  border-radius: 100%;
  padding: 2px;
  width: 60px;
  position: relative;
  animation: rolling 1s ease-in-out infinite;
  box-shadow: 2px 2px 10px 1px rgba(2, 2, 2, 0.1);
  margin: auto;
  display: flex;
}

.marginBottom {
  margin-bottom: 10px;
}

.rolling {
  background-color: #fff;
  border-radius: 100%;
  padding: 2px;
  width: 60px;
  position: fixed;
  animation: rolling 1s ease-in-out infinite;
  box-shadow: 2px 2px 10px 1px rgba(2, 2, 2, 0.1);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@keyframes rolling {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rolling {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: trotate(360deg);
  }
}

.carousel > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.carousel {
  width: 28%;
  height: 45%;
  position: absolute;
  right: 18%;
  top: 40%;
  animation: fadeLeft 800ms ease-in-out;
}

.carousel > * {
  animation: 12s autoplay infinite;
  overflow: hidden;
}

.carousel > img:nth-child(1) {
  animation-delay: 0s;
}
.carousel > img:nth-child(2) {
  animation-delay: -4s;
}
.carousel > img:nth-child(3) {
  animation-delay: -8s;
}

@keyframes autoplay {
  0% {
    opacity: 1;
    width: 100%;
    box-shadow: 2px 2px 10px 3px #16191c1c;
  }
  33.32% {
    width: 100%;
    opacity: 1;
  }
  33.33% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}

form > input,
form > textarea,
form > button {
  width: 100%;
}

form > input:focus {
  width: 100%;
}

textarea {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #ececec;
  box-shadow: 0 0 0 0 transparent;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 100px !important;
  margin-top: 0px !important;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  transition: 0.15s;
  padding-left: 10px;
  padding-top: 10px;
  top: 0;
  right: 0;
  max-width: 100%;
  font-family: "Poppins";
}

.disabled:disabled,
.disabled[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
a.disabled,
a.disabled:hover,
li.disabled,
li.disabled:hover,
.btn.disabled,
.btn.disabled:hover,
button.disabled,
button.disabled:hover {
  pointer-events: none;
  background-color: #dfdfdf !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  color: #9f9f9f !important;
  cursor: default;
}
.react-datepicker-wrapper {
  width: 100% !important;
  z-index: 999;
}

.modal-addTask .react-datepicker-wrapper,
.modal-updateTask .react-datepicker-wrapper {
  width: 80% !important;
  z-index: 999;
}

.input-datePicker {
  width: 100%;
}

.downloadPDF {
  width: 70px;
  cursor: pointer;
  transition: 0.2s;
}

.downloadPDF:hover {
  transition: 0.2s;
  transform: scale(1.1);
}

.tooltipPDF::after {
  display: none;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #444;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 50;
  top: 48px;
  left: -47px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  z-index: 5;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444 transparent;
}

.tooltip.del--event .tooltiptext::after {
  content: "";
  display: none;
}

.tooltip-copy .tooltiptext {
  padding: 5px !important;
  top: auto !important;
  left: auto !important;
  margin-top: 50px;
  margin-left: -55px;
  position: absolute !important;
}

.tooltip-copied .tooltiptext {
  visibility: visible !important;
  top: auto !important;
  left: auto !important;
  margin-top: 50px;
  margin-left: -105px;
  position: absolute;
}
.none {
  display: none;
}

.time-spent {
  position: absolute;
  right: 50px;
  width: 42%;
  top: 500px;
}

.logo.home {
  left: 0;
  right: 0;
  top: 0;
  width: 200px;
  margin: auto;
  display: block;
  position: absolute;
}

.logo.home svg {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .card-item-info {
    display: none;
  }
  .react-select__option--is-focused {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.desktop-toolbar {
  display: none;
}

@media only screen and (min-width: 600px) {
  .desktop-toolbar {
    display: block;
  }
  .toolbar {
    display: none;
  }
  .container.home h2 {
    margin-left: 20px;
  }
  .params {
    width: 400px !important;
  }
  .modal {
    width: 600px;
    height: 82%;
    top: 0;
    border-radius: 20px;
  }
  .toast {
    width: 600px !important;
  }
  .tabBar {
    left: 30px !important;
    bottom: 0 !important;
    top: 0 !important;
    margin: auto 0 auto 0 !important;
    width: 100px !important;
    height: 90% !important;
    z-index: 998 !important;
  }
  .tabBar ul {
    flex-direction: column;
  }

  .card-container {
    margin-left: 170px !important;
  }
  .container {
    padding-left: 170px !important;
    margin-left: 0 !important;
    width: 650px !important;
  }
  h1.title.text-center {
    text-align: center !important;
    padding: 0 !important;
  }
  .center-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 !important;
  }
  .modal-full {
    width: 90%;
  }
  .sidebar h1.title {
    padding-left: 60px !important;
  }
  h1.title {
    width: auto !important;
    text-align: left !important;
    padding-left: 170px !important;
  }
  .modal h1.title {
    text-align: center !important;
    padding-left: 0 !important;
  }
  .parent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .left {
    grid-area: 1 / 1 / 7 / 7;
  }
  .right {
    grid-area: 1 / 7 / 7 / 13;
  }
  .Container-container.css-1kbmavd {
    width: auto !important;
    height: 100% !important;
    padding-left: 160px;
    padding-right: 20px;
  }
  .btn-end-bottom {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 995;
    width: auto;
    background-color: #30c4f5;
    height: 50px;
    border-radius: 30px;
    color: #fff;
    display: flex;
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    box-shadow: 3px 3px 7px 3px #30c4f528;
    line-height: 21px;
    transition: 0.3s;
    font-size: 14px;
  }
  .btn-end-bottom:hover {
    transform: scale(1.05);
    transition: 0.3s;
    background-color: #6acef0;
  }
  .card-container {
    flex-direction: row;
  }
  .card-block {
    flex: 1 0 100px;
  }
  .auth-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1450px) {
  .card-item-info {
    height: 35px;
  }
  .btn-edit {
    right: 85px !important;
  }
}

.closeInput:focus {
  background-color: transparent !important;
}

.closeInput {
  background-color: transparent;
  color: rgb(106, 106, 106);
  transition: 0.3s;
  font-size: 25px !important;
  top: 36px;
  cursor: pointer;
}

.closeInput:hover {
  color: #444;
  transition: 0.3s;
}

.search {
  position: relative;
  top: 7px;
  left: 10px;
  width: 25px;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 20px;
}

.search:hover {
  transform: scale(1.05);
  transition: 0.2s;
  filter: invert(0.3);
}

ul {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.round-account input {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

li {
  display: flex;
  list-style: none;
  height: 25%;
  position: relative;
  cursor: pointer;
  width: 95%;
  background-color: #16191c09;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
  font-size: 16px;
}

li:hover {
  background-color: #16191c1c;
}

a {
  text-decoration: none;
  color: #000000;
}

.container-block {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-table;
  justify-content: center;
  background-color: #fff;
}

.block-logo {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.block-logo > div > h1 {
  color: #000000;
  font-size: 55px;
  margin-left: 50px;
}
.block {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  border-radius: 10px;
  width: 100%;
  z-index: 1;
  margin-top: 20px;
}

.block-item {
  margin: -13px;
  flex: 1 0 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 200px;
  height: 200px;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.block-item:hover {
  transform: scale(1.1);
}

.block-item > p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #444;
  text-transform: initial;
  transition: 0.5s;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.block-item:hover > p {
  transition: 0.5s;
  transform: scale(1.1) translateY(-7px);
}

.logo {
  transition: 0.2s;
  position: absolute;
  left: 10px;
  top: 10px;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.account {
  width: 40px;
  height: 40px;
  background-color: rgb(245, 245, 245);
  border-radius: 7px;
  position: absolute;
  right: 20px;
  bottom: 5px;
  border: 1px solid rgb(235, 235, 235);
  box-shadow: 3px 3px 7px 2px #0000000c;
  transition: 0.2s;
  cursor: pointer;
}

.account:hover {
  transform: scale(1.05);
  box-shadow: 3px 3px 7px 2px #0000001a;
}

.account > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.back {
  cursor: pointer;
  position: fixed;
  width: 50px;
  top: 30px;
  left: 30px;
  transition: 0.3s;
  z-index: 999;
}

.back:hover {
  transform: scale(1.05) translateX(-5px);
  transition: 0.3s;
  filter: invert(0.3);
}

.transition-page-enter {
  opacity: 0;
  transition: all 500ms;
}
.transition-page-enter-active {
  opacity: 1;
  transition: all 500ms;
}
.transition-page-exit {
  opacity: 1;
  transition: all 500ms;
}
.transition-page-exit-active {
  opacity: 0;
  transition: all 500ms;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(10, 10);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(30, 30);
  }
}

.nav-item::after {
  background: #ffffff91;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

a:focus:not(:active)::after {
  animation: ripple 500ms ease-out;
}

.form-login {
  width: 80%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.container.home {
  width: 100%;
  text-align: left;
}

@media only screen and (max-width: 700px) {
  .btn--listPerson {
    display: none;
  }
  .minute {
    margin-left: 5px !important;
  }
  .minute:nth-child(2) {
    height: 46px;
  }
  .jour-entier {
    margin-top: 30px;
  }
  .point {
    display: none;
  }
  .select--end {
    margin-left: 0px;
    width: 45%;
    height: 46px;
  }
  .input--date-end {
    width: 44% !important;
  }
  nav > h2 {
    margin-left: -50px;
    margin-top: 15px;
    font-size: 20px !important;
  }
  .container p {
    font-size: 14px;
  }

  .container {
    width: 90%;
    margin-top: 20px;
  }
  .hello-background > h1 {
    font-size: 33px !important;
  }
  .card-container,
  .date-container {
    left: 0;
    width: 97%;
  }
  .tooltip-add > span {
    display: none;
  }
  .modal--success,
  .modal--error,
  .text-legende {
    display: none;
  }
  .click--event:hover {
    transform: none !important;
  }
  .click--event {
    font-size: 13px !important;
  }
  .animation-fadeIn > img {
    margin: auto !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
  }
  .btn--filter {
    left: 120px;
  }
  .input--date-end .react-datepicker-popper {
    left: -82px !important;
  }
  .input--date-end .react-datepicker-popper .react-datepicker__triangle {
    left: 140px !important;
  }
}

.form-login > .profil {
  width: 200px;
  height: 200px;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.form-login div > img {
  filter: grayscale(0);
}

.form-login div > p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #444;
  text-transform: initial;
  transition: 0.5s;
  position: absolute;
  bottom: 30px !important;
  left: 0;
  right: 0;
}

.btn-login,
.btn-updateTask {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  animation: btnTask 300ms ease-in-out;
}

@keyframes btnTask {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.encours.btn-updateTask:hover {
  background-color: #e7aa5a !important;
}

.ok.btn-updateTask:hover {
  background-color: #abd666 !important;
}

.atraiter.btn-updateTask:hover {
  background-color: #79cde9 !important;
}

.attente_client.btn-updateTask:hover {
  background-color: #e074a3 !important;
}

.headerTask > a {
  position: absolute;
  right: 100px;
  z-index: 10;
}

.square-account-loading {
  width: 80%;
  height: 80%;
  margin: auto;
  border-radius: 7px;
}

@keyframes loading-account {
  0% {
    filter: invert(0.3);
  }
  100% {
    filter: invert(0.8);
  }
}

.square-account-loading > img {
  width: 150px;
  filter: invert(0.3);
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  animation: loading-account 300ms ease-in-out infinite alternate;
}

.hello-background {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  animation: hello-fade 2s ease-in-out;
}

.hello-background > h1 {
  width: 100%;
  position: fixed;
  height: 25%;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
  font-size: 5em;
  color: #444;
  animation: slideLeft 500ms ease-in-out;
}

.hello-background > hr {
  z-index: 999;
  top: 52%;
  position: relative;
  border-radius: 0;
  margin: auto;
  border-bottom: 3px solid rgba(68, 68, 68, 0.52);
  animation: hrHello 1.9s ease-in-out;
}

@keyframes hrHello {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hello-fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.forgot-password > a {
  position: absolute;
  bottom: 15px;
  left: 20px;
  transition: all 0.2s;
  border-bottom: 1px solid transparent;
}

.forgot-password > a:hover {
  border-bottom: 1px solid #000000;
  transition: all 0.2s;
}

.block-home {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: 50px;
}

.block-home-item {
  margin: 10px;
  flex: 1 0 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 200px;
  height: 200px;
  transition: 0.4s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 5px rgba(68, 68, 68, 0.05);
  animation: fadeIn 500ms ease-in-out;
}

.block-home-item:hover {
  transform: scale(1.1);
  box-shadow: 2px 2px 10px 5px rgba(68, 68, 68, 0.2);
}

.block-home-item > p {
  font-size: 13px;
  font-weight: 800;
  text-align: center;
  color: rgb(146, 146, 146);
  text-transform: initial;
  transition: 0.5s;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}

.block-home-item:hover > p {
  transition: 0.3s;
  transform: translateY(-5px);
  color: #fff;
}

.block-home-item:hover {
  background-color: #30c4f5;
}

.block-home-item > img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 15px;
  width: 110px;
  padding: 15px;
  transition: 0.2s;
  filter: invert(0.7);
}

.block-home-item:hover > img {
  transform: scale(1.1);
  transition: 0.2s;
  filter: invert(1);
}

.animation-page {
  animation: animationPage 400ms ease-in-out;
}

@keyframes animationPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.lock {
  position: absolute;
  width: 20px;
  top: 7px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  filter: invert(0.2) !important;
}

.lock:hover {
  transition: 0.2s;
  transform: scale(1.05);
  filter: invert(0.4) !important;
}

.item--date {
  position: relative;
  margin: 10px;
  margin-top: 15px;
  height: auto;
  color: #444;
  padding: 15px;
  transition: all 0.4s;
  text-align: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid #00000015;
}

.item--date > div {
  padding: 0px;
  position: relative;
  top: -10px;
}

.carre-cgre {
  position: absolute;
  width: 220px;
  bottom: 10px;
  right: 10px;
  animation: grayscale 1s ease-in-out;
}

@keyframes grayscale {
  0% {
    filter: grayscale(1);
  }
  100% {
    filter: grayscale(0);
  }
}

.icon-edit {
  cursor: pointer;
  position: absolute;
  object-fit: contain;
  width: 30px;
  right: 200px;
  top: 15px;
  transition: 0.2s;
  filter: invert(0.2);
}

.icon-edit:hover {
  transform: scale(1.08);
  transition: 0.1s;
  filter: invert(0.3);
}

.idTag {
  position: absolute;
  display: flex;
  left: 560px;
  top: 22px;
}

.idTag div > img {
  width: 30px;
  height: 30px;
  filter: invert(1);
  cursor: pointer;
  transition: 0.3s;
}

.idTag div > img:hover {
  transform: scale(1.1);
  transition: 0.2s;
  filter: drop-shadow(2px 2px 10px hsl(0, 0%, 0%)) invert(1);
}

.idTag-Link {
  position: fixed;
  z-index: 70;
  top: 17px;
  left: 35%;
}

.idTag-Link > img {
  width: 30px;
  height: 30px;
}

.idTag-Link > input {
  top: -7px;
  left: 45px;
  width: 220px;
  position: absolute;
}

.idTag .tooltip > span {
  left: -50px !important;
}

.idTag .tooltip-copied > span {
  left: 55px !important;
  margin-top: 48px;
}

.idTag-Link > a {
  position: relative;
  left: 245px;
  top: -10px;
  background-color: #30c4f5;
  border-radius: 7px;
  color: #fff;
  font-weight: 600;
  padding: 10px;
  border: 1px solid transparent;
  transition: 0.2s;
}

.idTag-Link > a:hover {
  background-color: #ffffff;
  border: 1px solid rgba(68, 68, 68, 0.568);
  color: #444;
  transition: 0.1s;
}

div.lienged div:nth-child(2) > span {
  left: 95px !important;
}

div.lienged div div > span {
  left: 200px !important;
}

.preview {
  background-color: #fff !important;
  border: none !important;
  cursor: default !important;
  resize: none;
}

.select.preview::after {
  content: inherit !important;
  cursor: default !important;
}

.headerTask {
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transition: 0.5s;
}

.headerTask > * {
  color: #fff;
}

.headerTask div > img,
.headerTask > img {
  filter: invert(1);
  width: 40px;
  height: 40px;
  position: absolute;
  top: 13px;
  left: 15px;
}

.time-modified {
  display: flex;
}

.time-modified p {
  margin: 10px;
}

.tooltip.mail > span {
  left: 600px !important;
  top: 69px;
}

.send-mail {
  width: 30px !important;
  height: 30px !important;
  top: 22px !important;
  left: 650px !important;
  cursor: pointer;
  transition: 0.3s;
}

.send-mail:hover {
  transform: scale(1.1);
  transition: 0.2s;
  filter: drop-shadow(2px 2px 10px hsl(0, 0%, 0%)) invert(1);
}

.btn-edit {
  color: #444;
  background-color: #fff;
  width: auto;
  z-index: 1;
  position: absolute;
  top: 15px;
  right: 300px;
  padding: 10px;
  padding-left: 40px;
  border-radius: 7px;
  border: 1px solid transparent;
}

.btn-edit:hover {
  color: #fff;
  border: 1px solid #fff;
  width: auto;
  z-index: 1;
  position: absolute;
  top: 15px;
  padding: 10px;
  padding-left: 40px;
  border-radius: 7px;
}

.btn-edit:hover > img {
  filter: invert(1);
  transition: 0.2s;
}

.btn-edit > img {
  transition: 0.2s;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  top: 8px;
  filter: invert(0.3);
}

.modal--success {
  background-color: #fff;
  position: fixed;
  z-index: 999;
  display: flex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 95%;
  height: 90px;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 5px #00000021;
  animation-name: openModal, fadeOut;
  animation-duration: 200ms, 500ms;
  animation-delay: 0s, 1.1s;
}

@keyframes openModal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(0);
  }
  100% {
    opacity: 0;
  }
}

.modal.sheet h1 {
  text-align: center;
  font-weight: 500;
  margin-top: 70px;
  font-size: 20px;
}

.modal.sheet {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.modal.sheet .closeModal {
  top: 20px;
}

.toast.success {
  background-color: #93c93c;
}

.toast.error {
  background-color: #ff004d;
}

.no-anim {
  animation: none !important;
}

.anim-in {
  animation: openModal 200ms !important;
}

.toast {
  width: 90%;
  height: auto;
  border-radius: 20px;
  position: fixed;
  z-index: 1000;
  box-shadow: 2px 2px 10px 5px #00000021;
  animation-name: openModal, fadeOut;
  animation-duration: 200ms, 500ms;
  animation-delay: 0s, 1.6s;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
}

.toast p {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.dropzone {
  outline: 1px dashed #444;
  padding-top: 21.5%;
  padding-bottom: 21.5%;
  transition: 0.3s;
  width: 45%;
  margin-right: auto;
  height: 0px;
}

.dropzone:hover {
  background-color: rgba(189, 189, 189, 0.349);
  transition: 0.3s;
  cursor: pointer;
}

.modal--success > p {
  font-weight: 500;
  font-size: 14px;
}

.modal--fadeOut {
  animation-name: openModal, fadeOut !important;
  animation-duration: 200ms, 500ms !important;
  animation-delay: 0s, 1.5s !important;
  height: 85px !important;
  width: 350px !important;
}

.container--input--time {
  display: flex;
  margin-top: 15px;
  margin-left: -10px;
}

.container--input--time > input {
  width: 55px !important;
  margin-top: -20px;
  margin-right: 13px;
  position: relative;
}

.reloader {
  position: fixed;
  top: 10px;
  left: 365px;
  z-index: 60;
  cursor: pointer;
}
.reloader > img {
  width: 35px;
  transition: 0.2s;
  filter: grayscale(1) invert(0.9);
}

.reloader:hover > img {
  transition: 0.2s;
  transform: rotate(80deg);
  filter: grayscale(0) invert(0);
}

.click--event {
  cursor: pointer;
  transition: 0.2s;
  font-size: 15px;
  color: #fff;
}

.click--event:hover {
  transform: translateX(10px);
  transition: 0.2s;
}

.del--event {
  float: right;
}

.del--event a > img {
  width: 30px;
  margin-top: -20px;
  top: -10px;
}

.btn--del {
  background-color: #e42323;
  border-radius: 10px;
  width: 180px;
  margin: auto;
  display: block;
  position: relative;
  height: 45px;
  text-transform: initial;
  font-weight: 700;
  font-size: 1em;
  text-align: center;
  line-height: 2.8;
  border: 1px solid transparent;
  transition: 0.5s;
  color: #fff;
}

.btn--del:hover {
  border: 1px solid #00000021;
  transition: 0.3s;
  background-color: transparent;
  color: #e42323;
}

.input--date-end {
  width: 48%;
  position: absolute;
  right: 20px;
  top: 195px;
  z-index: 10;
}

#C {
  animation: line-anim 2s ease-in-out forwards 0.2s;
  stroke-dasharray: 15932;
  stroke-dashoffset: 15932;
  stroke: #404040;
}

#G {
  animation: line-anim 2s ease-in-out forwards 0.4s;
  stroke-dashoffset: 12573;
  stroke-dasharray: 12573;
  stroke: #404040;
}

#R {
  animation: line-anim 2s ease-in-out forwards 0.6s;
  stroke-dashoffset: 11922;
  stroke-dasharray: 11922;
  stroke: #404040;
}

#E {
  animation: line-anim 2s ease-in-out forwards 0.8s;
  stroke-dashoffset: 6860;
  stroke-dasharray: 6860;
  stroke: #404040;
}

#É {
  animation: line-anim 2s ease-in-out forwards 0.8s;
  stroke-dasharray: 1584;
  stroke-dashoffset: 1584;
  stroke: #404040;
}

#greenSquare {
  animation: line-green 2s ease-in-out forwards 1s;
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  stroke: #94cc3c;
}

#blueSquare {
  animation: line-blue 2s ease-in-out forwards 1s;
  stroke-dasharray: 1720;
  stroke-dashoffset: 1720;
  stroke: #30c5f5;
}

#orangeSquare {
  animation: line-orange 2s ease-in-out forwards 1s;
  stroke-dasharray: 1620;
  stroke-dashoffset: 1620;
  stroke: #f9991c;
}

#pinkSquare {
  animation: line-pink 2s ease-in-out forwards 1s;
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  stroke: #ef5a9b;
}

#blackSquare {
  animation: line-anim 2s ease-in-out forwards 1s;
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  stroke: #404040;
}

#point {
  animation: line-anim 2s ease-in-out forwards 1s;
  stroke-dasharray: 1840;
  stroke-dashoffset: 1840;
  stroke: #404040;
}

#point2 {
  animation: line-anim 2s ease-in-out forwards 1s;
  stroke-dasharray: 1826;
  stroke-dashoffset: 1826;
  stroke: #404040;
}

@keyframes line-orange {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  75% {
    fill: #f9991c;
    stroke: #f9991c;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #f9991c;
    stroke: transparent;
    stroke-dashoffset: 0;
  }
}

@keyframes line-green {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  75% {
    fill: #94cc3c;
    stroke: #94cc3c;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #94cc3c;
    stroke: transparent;
    stroke-dashoffset: 0;
  }
}

@keyframes line-blue {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  75% {
    fill: #30c5f5;
    stroke: #30c5f5;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #30c5f5;
    stroke: transparent;
    stroke-dashoffset: 0;
  }
}

@keyframes line-pink {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  75% {
    fill: #ef5a9b;
    stroke: #ef5a9b;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #ef5a9b;
    stroke: transparent;
    stroke-dashoffset: 0;
  }
}

@keyframes line-anim {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  75% {
    fill: #404040;
    stroke: #404040;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #404040;
    stroke: transparent;
    stroke-dashoffset: 0;
  }
}

.btn--listPerson.active {
  right: 200px;
  transition: 0.3s;
}

.btn--listPerson {
  transition: 0.3s;
  background-color: #fff;
  width: 37px;
  height: 56px;
  position: absolute;
  right: 0;
  top: 140px;
  padding: 10px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  box-shadow: 0px 0px 5px 5px #00000006;
}
.btn--listPerson:hover {
  background-color: #fff;
  box-shadow: 0px 0px 5px 5px #00000010;
}

.btn--listPerson:hover > svg {
  fill: #30c4f5;
  transition: 0.1s;
}

.btn--listPerson > svg {
  fill: #313131;
  width: 100%;
  height: 100%;
  position: relative;
  transition: 0.1s;
}

.dropdown--listPerson {
  animation: slideRight 300ms ease-in-out;
  width: 200px;
  height: auto;
  border-bottom-left-radius: 10px;
  position: absolute;
  box-shadow: 2px 2px 5px 5px #00000010;
  right: 0;
  top: 140px;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

.dropdown--listPerson li > img {
  width: 50px;
  border-radius: 50%;
}

.dropdown--listPerson > li {
  background-color: #fff;
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dropdown--listPerson li > svg {
  width: 12px;
  height: 12px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 23px;
}

.dropdown--listPerson li > p {
  font-size: 15.5px;
}

ul > li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown--listPerson > li:last-child {
  border-bottom-left-radius: 10px;
}

.dropdown--listPerson > li:hover {
  background-color: rgb(223, 223, 223);
}

.logo--update {
  margin-top: 30px;
  width: 150px;
  filter: drop-shadow(0px 25px 15px rgba(0, 0, 0, 0.3));
  animation: updateLoop 1.5s ease infinite alternate;
}

@keyframes updateLoop {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0px 25px 15px rgba(0, 0, 0, 0.3));
  }
  100% {
    transform: translateY(25px);
    filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.35));
  }
}

@keyframes arrowLoop {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(20px);
  }
}

.logo--update g {
  animation: arrowLoop 1.5s ease infinite alternate;
}

.logo--update g > path:nth-last-child(1) {
  fill: #e4e4e4;
}

.logo--update g > path:nth-last-child(2) {
  fill: #e4e4e4;
}

.logo--update g:nth-last-child(2) > path:nth-last-child(2) {
  fill: #ffffff;
}

.logo--update g:nth-last-child(2) > path:nth-last-child(1) {
  fill: #ffffff;
}

.logo--update > path:nth-last-child(3) {
  fill: #28b0dd;
}

.logo--update > path:nth-last-child(4) {
  fill: #30c4f5;
}

.new-task {
  position: relative;
  left: -2px;
  top: 0;
  width: 13px;
  fill: #30c4f5;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-top: 1px solid #00000011;
}

::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}

.title {
  text-align: center;
  margin-top: 50px;
  font-size: 24px;
  font-weight: 600;
  width: 100%;
}

.tabBar {
  background-color: #fff;
  width: 90%;
  height: 60px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  border-radius: 50px;
  box-shadow: 0px 2px 10px 2px #0000000c;
  z-index: 997;
  animation: openModal 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

li.active {
  color: #fff;
  background-color: #d1d1d1;
  font-weight: 600;
}

.tabBar ul {
  display: flex;
}

.tabBar ul li {
  padding: 0;
  width: 100%;
  list-style: none;
  height: 100%;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  font-size: 16px;
}

.tabBar svg,
.tabBar .round-account {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.card.rdv {
  background-color: #30c4f5;
}
.color-picker {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  margin-top: 10px;
}

.container-color-picker {
  position: relative;
  display: contents;
}

.container-color-picker div {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.color-picker > div {
  position: relative;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin: 7px;
  cursor: pointer;
  transition: 0.2s;
  flex: 1 0 50px;
  display: flex;
  justify-content: space-between;
  max-width: 50px;
}

.color-picker div:hover {
  transform: scale(1.07);
  transition: 0.2s;
}

.card.formation {
  height: 140px;
}

.card.formation h1 {
  font-size: 18px;
}

.card.formation p {
  font-size: 12px;
}

.card-date {
  display: flex;
  margin-left: 10px;
}
.card-date svg {
  width: 21px;
}

.card-date p {
  margin: 0 !important;
  margin-left: 5px !important;
  line-height: 2;
}

.card-content {
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  overflow: auto;
}
.card-content h1 {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  word-break: break-word;
}

.animated {
  content: "";
  position: absolute;
  display: block;
  top: -5px;
  left: 4px;
  z-index: -1;
  display: block;
  height: 104%;
  width: 99.3%;
  overflow: hidden;

  background-size: auto;
  background-size: 200% auto;
  animation: fadeIn 2s ease-in-out, shine 3s linear infinite;
  border-radius: 20px;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.card {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 95%;
  height: 225px;
  border-radius: 20px;
  margin-left: 10px;
  box-shadow: 4px 4px 10px 0 #00000010;
  color: #fff;
  animation: fadeIn 500ms ease-in-out;
}

.swiper-container {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

.card p {
  font-size: 14px;
  margin: 2px;
  margin-left: 10px;
  word-break: break-word;
}

.card button {
  background-color: #fff;
  color: #000;
  width: 150px;
}

.card button:hover {
  background-color: rgb(247, 247, 247) !important;
}

.params {
  text-align: left;
  width: 80%;
  margin: auto;
  margin-top: 80px;
}

.params p {
  font-size: 16px;
}

.circle {
  background-color: #000000;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  box-shadow: 2px 2px 5px 0 #0000001e;
  margin-top: 6px;
}

.circle > p {
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 2.2;
  text-align: center;
}

.react-datepicker__portal {
  animation: fadeIn 0.2s ease-in-out;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;
}

.btn-start {
  cursor: pointer;
  position: absolute;
  left: 30px;
  top: 30px;
}

.btn-start svg,
.btn-end svg {
  width: 38px;
  height: 38px;
}

.btn-end {
  position: absolute;
  right: 30px;
  top: 30px;
}

.line {
  border-top: 2px #000000 dotted;
  width: 100%;
  position: absolute;
  margin-top: 4px;
}

.circle-time {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
}

.bar-time {
  z-index: 1;
  width: 100%;
  position: absolute;
}

.Toolbar-toolbar {
  border-bottom: none !important;
}

.MainLayout-container {
  border: 1px solid rgb(209, 209, 209);
  border-radius: 10px;
}

.css-1kbmavd.Container-container {
  animation: fadeIn 500ms ease-in-out;
  padding-bottom: 100px;
}

.Container-container * {
  font-family: "Poppins" !important;
}

.MuiPaper-root * {
  font-family: "Poppins" !important;
  text-transform: capitalize;
}

.MuiBackdrop-root {
  background-color: #0000004f !important;
}

.Cell-dayView * {
  color: #000 !important;
}

.Cell-dayOfWeek {
  text-transform: capitalize;
  font-size: 1em !important;
}

.MuiIconButton-colorPrimary {
  color: #000 !important;
}

.Cell-selected {
  background-color: #30c4f5 !important;
}

.Indicator-nowIndicator {
  background-color: #000 !important;
}

.react-datepicker {
  font-size: 0.9rem;
  font-family: "poppins", sans-serif;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}
.react-datepicker__navigation {
  margin: 0;
  border-radius: 0;
}
.react-datepicker__navigation:hover {
  background-color: transparent !important;
}
.nothing {
  font-size: 14px;
  margin-left: 20px;
  color: rgb(121, 121, 121);
}

.modal.error div {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: auto;
  position: fixed;
  width: 90%;
  font-weight: 500;
  text-align: center;
}

.tick {
  animation: fadeIn 200ms ease-in-out;
  border-radius: 100%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  filter: invert(1);
}

.check {
  display: inline-block !important;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  box-sizing: content-box;
  animation: fadeIn 300ms ease-in-out;
  margin: auto;
}

.check:before {
  position: absolute;
  content: "";
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(30px / 4);
  height: calc(30px / 1.8);
  border: none;
  border-bottom: calc(40px / 8) solid #ffffff;
  border-right: calc(40px / 8) solid #ffffff;
  transform: rotate(45deg);
  box-sizing: content-box;
}

.toggle-switch p {
  margin-left: 60px;
  margin-top: 2px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #30c4f5;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #83d3ee;
}

.Cell-today {
  color: #30c4f5 !important;
}

.VerticalAppointment-content {
  padding-left: 0 !important;
}

.Appointment-appointment p {
  font-size: 11px;
  margin-top: 2.5px;
}

.react-datepicker__time-list-item--selected {
  background-color: #30c4f5 !important;
}

form {
  padding-bottom: 50px;
}

.invited-content-card {
  display: flex;
  margin-top: 25px;
  margin-left: 12px;
}

.invited-content-card .round-account {
  margin: -7px;
}

.description > svg {
  position: relative;
  top: 6px;
  left: -3px;
  width: 22px;
}

.description {
  margin-top: -7px;
  font-size: 14px;
  margin-left: 10px;
}

.invited-content-card.appointments {
  margin-left: 5px;
  margin-top: 0;
}

.invited-content-card.appointments .round-account {
  width: 25px;
  height: 25px;
}

.btn-text {
  background-color: transparent !important;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 50px;
  text-align: center;
  display: block;
}

.btn-text:hover {
  background-color: transparent !important;
}

.center {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.change-circle {
  background-color: #fff;
  padding: 10px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  position: relative;
  display: block;
  box-shadow: 2px 2px 6px 5px rgba(0, 0, 0, 0.1);
}

.change-circle svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 0.9em;
  font-weight: 400;
  color: rgb(38, 38, 38);
  display: block;
  margin: auto;
  border: 1px dashed #000;
  padding: 10px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  border: 1px solid #000;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10px !important  ;
}

.react-datepicker__month-container {
  width: 100% !important;
}

.react-datepicker__time-container {
  width: 100% !important;
}

.react-datepicker__time-list {
  overflow-x: hidden !important;
}

.MuiButtonBase-root:hover {
  background-color: transparent !important;
}

.Root-root {
  width: 20%;
  display: flex;
}

.MuiButton-textPrimary {
  color: #30c4f5 !important;
}

.MuiToolbar-root .MuiButtonBase-root {
  width: auto !important;
}

.Cell-today {
  background-color: #30c4f5 !important;
  color: #fff !important;
}

.modal .react-datepicker__portal {
  right: 0 !important;
  width: auto !important;
}

.modal.addevent .react-datepicker {
  position: absolute;
  width: auto;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.MainLayout-header .Cell-cell {
  height: auto !important;
}

.HorizontalAppointment-title {
  margin-top: 2px;
  margin-left: -6px;
}

.rcp {
  margin: auto;
  box-shadow: 2px 2px 10px 5px #00000017;
}

.css-sizvqo-MuiTableCell-root {
  border-bottom: 1px solid rgb(206, 206, 206) !important;
  border-right: 1px solid rgb(206, 206, 206) !important;
}

.css-sizvqo-MuiTableCell-root.Cell-cell,
.css-eio5t.Cell-cell {
  height: 135px !important;
}

@media (max-width: 700px) {
  .css-1675apn-MuiTableCell-root.Cell-cell {
    width: 80px;
  }

  .css-18tcpub-MuiTableCell-root.Cell-cell {
    width: 80px;
  }
}

.btn-duplicate {
  position: absolute;
  right: 77px;
  top: 51px;
}

.btn-duplicate svg {
  width: 28px;
  height: 28px;
}

.react-datepicker__header {
  background-color: rgb(245, 245, 245);
  border-bottom: none;
}

.btn.add-calendar {
  font-size: 14px;
  line-height: 3.7;
}

.item-absence {
  padding: 5px;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  width: 90%;
  height: 55px;
  margin: auto;
  margin-top: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.056);
  cursor: pointer;
  transition: 0.2s;
  display: block;
}

.item-absence:hover {
  background-color: #ff8e25;
  transform: scale(1.04);
  transition: 0.2s;
}

.item-absence.accept:hover {
  background-color: #93c93c;
}

.item-absence.refuse:hover {
  background-color: #ff004d;
}

.item-absence:hover * {
  color: #fff;
  transition: 0.2s;
}

.item-absence p {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

.item-absence label {
  font-size: 13px;
  color: #000;
  font-weight: 600;
  margin-left: 10px;
}

.toast-not-connected {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #ff004d;
  color: #fff;
}

.feed-shared-inline-show-more-text__see-more-less-toggle {
  display: none;
}

.MuiPaper-elevation8 {
  border-radius: 25px !important;
  padding: 10px;
}

.MuiOutlinedInput-notchedOutline legend {
  display: none;
}
.Switcher-inputRoot {
  border-radius: 10px !important;
}

.task {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.task-people {
  grid-area: 5 / 11 / 7 / 13;
}
.task-description {
  grid-area: 2 / 1 / 5 / 11;
  font-size: 0.9em;
  color: #6a6a6a;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-title {
  font-size: 1.2em;
  font-weight: 500;
  grid-area: 1 / 1 / 2 / 11;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.task-more {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  width: 50px;
}
.task-more:hover {
  background-color: transparent !important;
}
.task-date {
  grid-area: 6 / 1 / 7 / 8;
  font-weight: 400;
  color: #6a6a6a;
}

.before-blue::before {
  content: "";
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  background-color: #30c5f5;
  margin-right: 15px;
  border-radius: 4px;
  top: 2px;
}

.before-orange::before {
  content: "";
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  background-color: #ff8e25;
  margin-right: 15px;
  border-radius: 4px;
  top: 2px;
}

.before-green::before {
  content: "";
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  background-color: #93c93c;
  margin-right: 15px;
  border-radius: 4px;
  top: 2px;
}

.popover {
  width: 200px;
  height: auto;
  background-color: #fff;
  position: absolute;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.122);
  right: 55px;
  top: 10px;
  padding: 10px;
  border-radius: 20px;
  animation: fadeIn 500ms;
  z-index: 2;
}

.backdrop-element {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.123);
  backdrop-filter: blur(5px);
  animation: fadeIn 500ms;
  z-index: 1;
}

.popover li {
  border-radius: 5px;
}
